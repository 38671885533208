import axios from 'axios';
import md5 from 'md5';
import snakeCaseKeys from 'snakecase-keys';
import uuid from 'uuid/v4';
import context from './context';

export default class MsAnalytics {
  constructor(hostUrl, version) {
    this.hostUrl = hostUrl;
    this.version = version;
  }

  track(eventType, eventName, eventData, userData = {}) {
    const payload = this.formatPayload(eventType, eventName, eventData, userData);
    this._send(payload);
  }

  formatPayload(eventType, eventName, eventData, userData) {
    const contextInfo = snakeCaseKeys(context());
    if (eventData && eventData.pageUrl) {
      contextInfo.page_url = eventData.pageUrl;
    }

    const payload = {
      type: eventType,
      context: contextInfo,
      user: snakeCaseKeys(userData),
      body: {
        event_name: eventName,
        data: snakeCaseKeys(eventData),
      },
      sent_at: new Date().toISOString(),
    };

    payload.event_id = `mse-${md5(JSON.stringify(payload) + uuid())}`;
    payload.version = this.version;
    return payload;
  }

  _send(payload) {
    return axios.post(this.hostUrl, payload).catch((error) => {
      console.error(error);
    });
  }
}
