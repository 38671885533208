import Utils from './utils';

export default class Tracker {
  constructor({ analytics }, user) {
    this.analytics = analytics;
    this.user = user;
    this.delayMs = 500;
  }

  /**
    Method to send event data to tracking service
  * */
  fireEvent(eventName, attrs = {}, callback = null) {
    if (!eventName) {
      throw new Error('eventName must be present!');
    }

    try {
      this.analytics.track('event', eventName, attrs, this.user.getJson());
    } catch (err) {
      console.log('tracker.FireEvent Error:', err);
    } finally {
      this._executeCallback(callback);
    }
  }

  /**
    Method to send a page view event with default page information
  * */
  pageView(pageData = {}, callback = null) {
    try {
      this.analytics.track('page', 'PageView', pageData, this.user.getJson());
    } catch (err) {
      console.log('tracker.pageView Error:', err);
    } finally {
      this._executeCallback(callback);
    }
  }

  /**
    Method to add an experiment that is not from lighthouse. After calling this function,
    all requests in the current page will contain the newly added experiment.
  * */
  addExperiment(experimentId, experimentValue) {
    if (!experimentId || !experimentValue) {
      throw new Error('experimentId and experimentValue are required');
    }

    this.user.addExperiment(experimentId, experimentValue);
  }

  /**
    Method to set a user attribute such as email, name, user_id, etc. This attribute will be
    attributed to the user object in the tracking payload.
  * */
  setUserProperties(properties = {}) {
    this.user.setProperties(properties);
  }

  /**
    Returns anonymous_id of the user
  * */
  getAnonymousId() {
    return this.user.anonymousId;
  }

  /**
    Method to execute methods given by a queue
  * */
  executeQueuedEvents(queue) {
    if (!queue || queue.length < 1) {
      return;
    }

    const trackerScope = this;
    queue.forEach((e) => {
      const methodName = e[0];
      const args = e.slice(1);
      trackerScope[methodName](...args);
    });
  }

  _executeCallback(callback) {
    if (!Utils.isFunction(callback)) {
      return;
    }

    Utils.delay(this.delayMs).then(() => {
      callback();
    });
  }
}
