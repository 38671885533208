import Cookies from 'js-cookie';
import Utils from './utils';

export default class Lighthouse {
  constructor(cookieId) {
    this.cookieId = cookieId;
    this.cookies = Cookies.withConverter({
      write: (value) => {
        const encodedValue = encodeURIComponent(String(value));
        return encodedValue.replace(
          /%(2C|23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
          decodeURIComponent,
        );
      },
    });
  }

  getExperiments() {
    if (!this.isCookieSet()) {
      return {};
    }

    return Utils.transformCookieDataToJson(this._getCookie());
  }

  isCookieSet() {
    if (!this.cookieId) {
      return false;
    }

    const cookieValue = this._getCookie();
    return (typeof (cookieValue) !== 'undefined'
            && cookieValue !== '');
  }

  _getCookie() {
    return Utils.transformCookieData(Cookies.get(this.cookieId));
  }
}
