export default class User {
  constructor(anonymousId, ampId = null, unknownUserId = null, msId = null) {
    if (anonymousId === '' || !anonymousId) {
      throw new Error('anonymousId is required');
    }

    this.anonymousId = anonymousId;
    this.ampId = ampId;
    this.unknownUserId = unknownUserId;
    this.msId = msId;
    this.experiments = {};
  }

  setExperiments(experiments) {
    if (!experiments) {
      this.experiments = {};
      return;
    }

    this.experiments = experiments;
  }

  addExperiment(experimentId, experimentValue) {
    this.experiments[experimentId] = experimentValue;
  }

  setProperties(properties) {
    this.attributes = { ...this.attributes, ...properties };
  }

  getJson() {
    const ids = {
      anonymousId: this.anonymousId,
      ampId: this.ampId,
      experiments: this.experiments,
    };

    return Object.assign(ids, this.attributes);
  }
}
