/* global MS_ANALYTICS_URL, EXPERIMENTS_COOKIE_ID, VERSION */

import 'core-js/features/promise';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/array/is-array';
import IdService from './id_service';
import Lighthouse from './lighthouse';
import MsAnalytics from './ms_analytics';
import Tracker from './tracker';
import User from './user';

const create = (config) => {
  const anonymousId = IdService.getId();
  const ampId = IdService.getAmpId();
  const lighthouse = new Lighthouse(EXPERIMENTS_COOKIE_ID);
  const user = new User(anonymousId, ampId);
  user.setExperiments(lighthouse.getExperiments());
  const msAnalytics = new MsAnalytics(MS_ANALYTICS_URL, VERSION);
  const tracker = new Tracker(
    { analytics: msAnalytics },
    user,
  );

  tracker.executeQueuedEvents(window.tracker);

  if (!config || !config.isSpa) {
    tracker.pageView();
  }

  return tracker;
};

const trackerConfig = window.trackerConfig || {};
window.tracker = create(trackerConfig);

export default create;
