/* global ENVIRONMENT */
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import url from 'url';
import uuid from 'uuid/v4';
import Utils from './utils';

const msAnonymousCookieId = 'ms_anonymous_id';
const ampLinkerId = 'ms_linker';

const getDomain = () => {
  if (ENVIRONMENT === 'development') {
    return window.location.hostname;
  }

  const regex = /\w*\.?((moneysmart|mssgdev|mshkdev|mstwdev|msphdev)\.\w+)$/;
  const result = regex.exec(window.location.hostname);
  return result[1];
};

const saveId = (id) => {
  Cookies.remove(msAnonymousCookieId);
  Cookies.set(msAnonymousCookieId, id, { domain: getDomain() });
};

const getId = () => {
  const cookieAnonymousId = Cookies.get(msAnonymousCookieId);

  if (cookieAnonymousId) {
    const oldId = Utils.removeQuotes(cookieAnonymousId);
    saveId(oldId);
    return oldId;
  }

  const newId = uuid();
  saveId(newId);
  return newId;
};

const getAmpId = () => {
  const urlObj = url.parse(window.location.href);
  const queryParams = queryString.parse(urlObj.query);
  const rawLinkerValue = queryParams[ampLinkerId];
  if (!rawLinkerValue) {
    return null;
  }

  const [idValue] = rawLinkerValue.split('*').slice(-1);
  return Base64.decode(idValue);
};

export default { getId, getAmpId };
