/* Removes curly braces from the lighthouse's cookie value */
const transformCookieData = (data) => {
  if (data) {
    return data.replace(/[{}\s]/g, '');
  }
  return data;
};

/* Converts strings with format: id1:value1, id2:value2 to JSON */
const transformCookieDataToJson = (data) => {
  const object = {};
  const dataArray = data.split(',');
  dataArray.forEach((e) => {
    const [key, value] = e.trim().split(':');
    if (key && value) {
      object[key] = value;
    }
  });

  return object;
};

/* Converts json data formatted by `transformCookieDataToJson` back to an array */
const jsonCookieDataToArray = (data) => {
  const resultArray = [];
  Object.entries(data).forEach(([key, value]) => {
    if (key && value) {
      resultArray.push(`${key}:${value}`);
    }
  });

  return resultArray;
};

const removeQuotes = (string) => string.replace(/(^"|"$)/g, '');

const delay = (delayMs) => new Promise((resolve) => {
  setTimeout(resolve, delayMs);
});

const isFunction = (object) => (!!object) && typeof (object) === 'function';

const randomInt = (minInt = 0, maxInt) => minInt + Math.floor(maxInt * Math.random());

export default {
  delay,
  isFunction,
  jsonCookieDataToArray,
  randomInt,
  removeQuotes,
  transformCookieData,
  transformCookieDataToJson,
};
