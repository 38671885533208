/* global COUNTRY_CODE */

import UAParser from 'ua-parser-js';

export default function context() {
  const { userAgent } = window.navigator;
  const uaParser = new UAParser(userAgent);

  const device = uaParser.getDevice();
  if (!device.type) {
    device.type = 'desktop';
  }

  let websiteCountry = '';
  if (COUNTRY_CODE) {
    websiteCountry = COUNTRY_CODE.toUpperCase();
  }

  return {
    title: document.title,
    pageUrl: document.URL,
    referrer: document.referrer,
    userAgent,
    device,
    operatingSystem: uaParser.getOS(),
    browser: uaParser.getBrowser(),
    websiteCountry,
  };
}
